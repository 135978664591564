@import '../scss/public/foundations_vars';

.ig-inline-status {
  font-size: $igTypoSmallSize;
  font-weight: $igTypoFontWeightBold;
  //line-height: 20px;
  color: var(--ig-color-status-grey);
  position: relative;

  display: flex;
  align-items: center;

  &:before {
    display: block;
    left: 0;
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
    margin-right: 7px;
    content: ' ';
    border-radius: 50%;
    background: var(--ig-color-status-grey);
  }


  &.inline-status--no-dot {
    &::before {
      display: none;
    }
  }

  &.ig-inline-status--is-red {
    color: var(--ig-color-warning-red);
    &:before {
      background: var(--ig-color-warning-red);
    }
  }
  &.ig-inline-status--is-green {
    color: var(--ig-color-success-green);
    &:before {
      background: var(--ig-color-success-green);
    }
  }
  &.ig-inline-status--is-blue-without-spinning {
    color: var(--accent-color, var(--ig-color-sky-blue));
    &:before {
      background: var(--accent-color, var(--ig-color-sky-blue));
    }
  }
  &.ig-inline-status--is-blue {
    color: var(--accent-color, var(--ig-color-sky-blue));
    &:before {
      box-sizing: border-box;
      background: transparent;
      border: 1px solid var(--accent-color, var(--ig-color-sky-blue));
      border-bottom: 1px solid transparent;
      animation: rotate 2s infinite linear;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
