@import '../../foundations/colors/colors.public';
@import '../../foundations/typographie/typographie.public';
@import '../../../scss/public/base_variables';
@import '../../../scss/public/mix';

:host(.ig-object-card--is-full) {
  display: block;
  height: 100%;

  .ig-object-card {
    height: 100%;
  }
}

.ig-object-card {
  background-color: var(--ig-color-card-background);
  border: 1px solid var(--ig-color-card-background);
  .highcontrast & {
    border: 1px solid var(--ig-color-card-border)!important;
  }
  padding: 14px 24px;
  transition: box-shadow 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $igBorderRadiusL;



  &.small-mode {
    padding: 11px 14px 12px;
  }

  &.loading {
    cursor: wait;
    opacity: 0.8;
    border: 1px solid var(--ig-color-card-border);

    .ig-object-card-loading-container {
      position: absolute;
      top: -1px;
      left: 0px;
      height: $igBorderRadiusL;
      width: 100%;
      overflow-x: hidden;

      border-top-left-radius: $igBorderRadiusL;
      border-top-right-radius: $igBorderRadiusL;


      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 2px;
        background: var(--primary-color);
        animation-name: bouncing-bar;
        animation-duration: 1s;
        animation-direction: alternate;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }



      @keyframes bouncing-bar {
        // this frames will let the bar disappear on left and right edge
        // 0% { left: -45%; width: 25%; }
        // 50% { left: 25%; width: 50%; }
        // 100% { left: 120%; width: 25%;}

        // this frames will keep the bar visible
        0% { left: -5%; width: 25%; }
        50% { left: 25%; width: 50%; }
        100% { left: 80%; width: 25%;}
      }



    }

  }

  &.ig-object-card__has-hover:not(.loading):not(.disabled) {
    cursor: pointer;
    transition: transform 0.4s, box-shadow 0.4s, background-color 0.4s;
    &:hover, &:focus-within {
      // background-color: var(--ig-color-white);
      // z-index: 1;

      @include box-shadow();
      transform: scale(1.03);

      &.ig-object-card__has-hover-noscale {
        transform: none;
        background-color: var(--ig-color-card-background-hover);
      }
    }


  }

  // @keyframes loadingAnim {
  //   0%{background-position:150% 150% }
  //   // 90%{background-position:-140% 50%}
  //   100%{background-position:-50% -50%}
  // }
}

/**************************
*  Header
**************************/

.ig-object-card__header {
  margin-bottom: 5px;
  .small-mode & {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .small-mode & {
    max-width: 100%;
    overflow: hidden;
    justify-content: flex-start;
  }
  &>* {
    flex: 1 1 auto;
  }

  .ig-object-card__header-options {
    margin-right: -13px;
    flex: 0 0 auto;
  }
}

// SEE inline-status!



:host ::ng-deep .ig-object-card__header-options .p-button.p-button-sm {
  height: 30px;
  width: 30px;
  color: var(--primary-color);
  &:hover, &:focus-within {
    color: var(--primary-dark-color);
  }
}

/**************************
*  Body
**************************/
.ig-object-card__body {
  flex: 1;
}

.ig-object-card__main-link {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
  margin-bottom: 10px;
  white-space: initial;


  .small-mode & {
    font-size: 0.875rem!important;
  }


  &.ig-object-card__main-link--has-link {
    &:hover, &:focus-within {
      cursor: pointer;
      text-decoration: underline;
      text-underline-position: below;
    }
  }
}

/**************************
*  Inner Content can be just a ul>li>(span|span)
**************************/
:host ::ng-deep {
  .ig-object-card__body-content {
    ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      .small-mode & {
        justify-content: space-between;
      }

      li {
        padding: 0 15px;
        border-left: 1px solid var(--ig-color-card-border);

        .small-mode & {
          flex: 1 1 auto;
        }

        display: block;
        margin-bottom: 10px;

        &:first-child {
          padding-left: 0;
          border: 0;
        }

        span {
          display: block;
          text-transform: none;

          .small-mode & {
            font-size: $igTypoSmallSize;
            text-transform: none;
          }
          color: var(--ig-color-label);
          white-space: nowrap;

          & + span {
            display: block;
            font-size: $igTypoLargeSize;
            line-height: normal;

            .small-mode & {
              font-size: $igTypoSmallSize;
            }
            font-weight: normal;
            color: var(--ig-color-card-label);
            white-space: nowrap;
          }

          & + strong {
            display: block;

            @include igTypoLargeNumbers();

            .small-mode & {
              font-size: 14px;
              line-height: 16px;
            }


            color: var(--ig-color-card-label);
            white-space: nowrap;
          }
        }
      }
    }

    .ig-object-card__item-row {
      display: flex;

      .ig-object-card__item {
        padding: 0 15px;
        border-left: 1px solid var(--ig-color-card-border);
        &:first-child {
          padding-left: 0;
          border: 0;
        }
      }
    }

    .ig-object-card__item {
      display: block;
      margin-bottom: 10px;
    }

    .ig-object-card__item-lable {
      display: block;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: var(--ig-color-label);
    }
    .ig-object-card__item-value {
      display: block;
      font-size: 16px;
      line-height: 20px;
      color: var(--ig-color-card-label);
      font-weight: normal;

      &.ig-object-card__item-value--bold {
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }
}

/**************************
*  Footer
There is no Padding in the Footer!
**************************/
.ig-object-card__footer {
  margin: 0 -25px -15px;
  border-top: 1px solid var(--ig-color-card-border);

  &.ig-object-card--no-footer {
    display: none;
  }
}

.ig-object-card__animation-success, .ig-object-card__animation-failure {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--ig-color-white80);
  display: flex;
  align-items: center;
  justify-content: center;


  @keyframes cardAnimation {
    0% { font-size: 1px; }
    50% { font-size: 128px; }
    100% { font-size: 80px; }
  }

  i {
    font-size: 1px;
  }

  &.animate {
    i {
      animation-name: cardAnimation;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}

.ig-object-card__animation-success > i {
  color: var(--ig-color-success-green);
}
.ig-object-card__animation-failure > i {
  color: var(--ig-color-warning-red);
}
