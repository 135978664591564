 //Mixin
 @mixin igTypoHeadline1 {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoH1Size;
  line-height: $igTypoH1LineHeight;
}
@mixin igTypoHeadline2 {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoH2Size;
  line-height: $igTypoH2LineHeight;
}
@mixin igTypoHeadline3 {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoH3Size;
  line-height: $igTypoH3LineHeight;
}
@mixin igTypoHeadline4() {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoH4Size;
  line-height: $igTypoH4LineHeight;
}
@mixin igTypoHeadline5() {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoH5Size;
  line-height: $igTypoH5LineHeight;
}
@mixin igTypoHeadline6() {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoH6Size;
  line-height: $igTypoH6LineHeight;
}
@mixin igTypoPara {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoFontWeightDefault;
  font-size: $igTypoParaSize;
  line-height: $igTypoParaLineHeight;
}
@mixin igTypoStrong {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoParaSize;
  line-height: $igTypoParaLineHeight;
}

@mixin igTypoSmall {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoFontWeightDefault;
  font-size: $igTypoSmallSize;
  line-height: $igTypoSmallLineHeight;
}

@mixin igTypoTiny {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoFontWeightDefault;
  font-size: $igTypoTinySize;
  line-height: $igTypoTinyLineHeight;
}

@mixin igTypoLabel {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoLabelWeight;
  font-size: $igTypoLabelSize;
  line-height: $igTypoLabelHeight;
}

@mixin igTypoLabelAsTitle {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoLabelTitleSize;
  line-height: $igTypoLabelTitleHeight;
}

@mixin igTypoLargeNumbers {
  font-weight: $igTypoLargeNumbersWeight;
  font-size: $igTypoLargeNumbersSize;
  line-height: $igTypoLargeNumbersHeight;
}

@mixin igTypoPanelTitle {
  font-weight: $igTypoPanelTitleWeight;
  font-size: $igTypoPanelTitleSize;
  line-height: $igTypoPanelTitleHeight;
}