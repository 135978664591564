// Panel Class to Root node activates the panel Styles
&.ig-panel,
.ig-panel {
  @include igTypoPara();

  a {
    color: var(--link-color);
  }


  h1,
  .ig-h1 {
    @include igTypoHeadline1();
  }

  h2,
  .ig-h2 {
    @include igTypoHeadline2();
  }

  h3,
  .ig-h3 {
    @include igTypoHeadline3();
  }

  h4,
  .ig-h4 {
    @include igTypoHeadline4();
  }

  h5,
  .ig-h5 {
    @include igTypoHeadline5();
  }

  h6,
  .ig-h6 {
    @include igTypoHeadline6();
  }

  p,
  .ig-text {
    @include igTypoPara();
  }

  strong,
  .ig-text-strong {
    @include igTypoStrong();
  }

  small,
  .ig-text-small {
    @include igTypoSmall();
  }

  .ig-text-large,
  .ig-text-intro {
    font-size: $igTypoLargeSize;
  }

  .ig-text-tiny {
    @include igTypoTiny();
  }

  label,
  .ig-label {
    @include igTypoLabel();

    &.ig-label--is-selected {
      font-weight: $igTypoLabelWeightSelected;
    }
  }
  .ig-label-title {
    @include igTypoLabelAsTitle();
    display: block;

    sup {
      vertical-align: text-top;
    }
  }

}
