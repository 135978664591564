// Brand Typographie (Panel)
// Design Tokens
$igTypoFontFamily: var(--skin-font-family, Roboto);
$igTypoFontFamilyHeadline: var(--skin-font-family-headline);

$igTypoFontWeightDefault: 400;
$igTypoFontWeightRegular: 400;
$igTypoFontWeightBold: 500;
$igTypoFontWeightMedium: 500;
$igTypoFontWeightLight: 300;

$igTypoFontWeightItalic: italic;

$igTypoSize00: 10px;
$igTypoSize01: 12px;
$igTypoSize09: 13px;
$igTypoSize02: 14px;
$igTypoSize03: 16px;
$igTypoSize04: 18px;
$igTypoSize05: 20px;
$igTypoSize06: 26px;
$igTypoSize07: 36px;
$igTypoSize08: 42px;


//h1
$igTypoH1Size: $igTypoSize07;
$igTypoH1LineHeight: normal;

//h2
$igTypoH2Size: $igTypoSize06;
$igTypoH2LineHeight: normal;

//h3
$igTypoH3Size: $igTypoSize05;
$igTypoH3LineHeight: normal;

//h4
$igTypoH4Size: $igTypoSize04;
$igTypoH4LineHeight: normal;

//h5
$igTypoH5Size: $igTypoSize03;
$igTypoH5LineHeight: normal;

//h6
$igTypoH6Size: $igTypoSize03;
$igTypoH6LineHeight: normal;

//paragraph
$igTypoParaSize: $igTypoSize02;
$igTypoParaLineHeight: 1.57;

//large
$igTypoLargeSize: $igTypoSize03;
$igTypoLargeLineHeight: normal;

//small
$igTypoSmallSize: $igTypoSize01;
$igTypoSmallLineHeight: normal;

//tiny
$igTypoTinySize: $igTypoSize00;
$igTypoTinyLineHeight: normal;

//label
$igTypoLabelSize: $igTypoSize02;
$igTypoLabelHeight: normal;
$igTypoLabelWeight: $igTypoFontWeightDefault;
$igTypoLabelWeightSelected: $igTypoFontWeightBold;

$igTypoLabelTitleSize: $igTypoSize03;
$igTypoLabelTitleHeight: normal;

//large numbers
$igTypoLargeNumbersSize: $igTypoSize05;
$igTypoLargeNumbersHeight: 1;
$igTypoLargeNumbersWeight: $igTypoFontWeightBold;

//panel title
$igTypoPanelTitleSize: $igTypoSize05;
$igTypoPanelTitleHeight: normal;
$igTypoPanelTitleWeight: $igTypoFontWeightBold;


$igCardTitleSize: $igTypoSize05;
$igCardSmallTitleSize: $igTypoSize02;



$igIconSize03: 24px;
$igIconSize05: 36px;


// menus
$igMenuSecondLevelSize: $igTypoSize09;
$igMenuIconSize: $igIconSize03;
$igMenuIconSizeOld: $igIconSize05; // old generation of GS icons (smaller than new ones)

$igSideNavigationFontSize: $igTypoSize09;