/* Add your customizations of theme here */
@import '../variables';
@import 'foundations_vars';
//@import 'libs/flex';


body {
  /*
    .p-datatable-scrollable-body,
    .p-datatable-scrollable-header {
        padding: 0 10px;
        margin: 0 -10px 0 -10px;
    }
     */

  .p-datatable-virtual-table {
    padding: 10px;
  }

  .p-datatable-scrollable-header {
    padding: 0px 10px;
    margin: 0 -10px;
  }

  .p-datatable-scrollable-body {
    margin: -20px -10px -10px;
    padding: 10px;
  }

  // Shadow on hover
  .p-datatable-auto-layout {
    > .p-datatable-wrapper {
      padding: 0 10px;
      margin: 0 -10px 0 -10px;
    }
  }

  .p-datatable {
    table {
      border-collapse: separate;
      border-spacing: 0 10px;
      &.p-datatable-scrollable-header-table {
        margin-bottom: -20px;
        margin-top: -10px;
      }
    }

    .td-content {
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .p-datatable-caption {
      padding: 0;
      border: 0;
      text-align: left;
      background: none;
    }

    .p-datatable-header {
      padding: 0;
      &:empty {display:none;}
    }

    .p-table-select-bar {
      padding: 15px 10px 15px 26px;
      line-height: 22px;
      color: var(--ig-color-text);
      margin-top: 10px;

      a {
        display: inline-block;
        margin-left: 10px;
        color: var(--link-color);
        text-decoration: none;
      }
    }

    .p-datatable-tbody > tr > td.empty-placeholder {
      text-align: center;
      font-size: 16px;
      padding: 2em 30%;
      color: var(--ig-color-label);
      @include respond-to(medium down) {
        padding: 2em 2em;
      }
    }

    .p-datatable-thead > tr > th {
      position: relative;
      text-transform: uppercase;
      text-align: left;
      border-right: 1px solid var(--ig-color-card-border);

      &:last-child {
        border-right: none;
      }

      .p-checkbox {
        .p-checkbox-box {
          background-color: $tableHeaderCellBg;
        }
      }
    }

    .p-sortable-column {
      .p-sortable-column-icon {
        position: absolute;
        right: 5px;
        vertical-align: initial;
      }
    }

    .p-datatable-tbody {
      > tr:hover, > tr:focus-within {
        box-shadow: var(--box-shadow);
        transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }

    .p-datatable-tbody > tr > td {
      overflow: hidden;
      text-overflow: ellipsis;

      &.allowoverflow {
        overflow: visible;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
      }

      a {
        color: var(--primary-color);
        text-decoration: none;
        &:hover, &:focus-within {
          color: var(--primary-color-hover);
        }
      }
    }

    .p-sortable-column {
      .p-sortable-column-icon {
        color: $tableHeaderCellIconColor;
      }

      &:focus {
        box-shadow: none !important;
      }

      &:not(.p-highlight):hover,
      &:not(.p-highlight):focus-within {
        background-color: $tableHeaderCellHoverBg;
        color: $tableHeaderCellTextHoverColor;

        .p-sortable-column-icon {
          color: $tableHeaderCellIconHoverColor;
        }
      }

      &.p-highlight {
        background-color: $highlightBg;
        color: $highlightTextColor;

        .p-sortable-column-icon {
          color: $highlightTextColor;
        }
      }
    }
  }

  /**
/// Light Table
*/
  .p-datatable.lighttable {
    border: 1px solid var(--ig-color-card-border);
    min-height: 260px;

    .p-datatable-scrollable-body table,
    .p-datatable-wrapper table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    .p-datatable-scrollable-header {
      margin: 0;
      padding: 5px 0px 10px;
      background: var(--ig-color-white);
    }

    .p-datatable-virtual-table {
      border-spacing: 0;
    }

    .p-datatable-tbody {
      > tr > td {
        padding: 15px 10px;
        border: 0;

        &.allowoverflow {
          padding: 0 10px;

          .p-dropdown {
            margin: 0;
          }
        }
      }

    }

    .p-datatable-thead {
      > tr > th {
        padding: 0.5em 0.857em;
        background: none;
        color: var(--table-header-font-color);
        border: 0;
      }
    }
  }
}
