
@import '../../foundations/colors/colors.public';
@import '../../foundations/typographie/typo.panel.vars';
@import '../../foundations/typographie/typo.panel.mixin';

@import '../../../scss/public/base_variables';

.ig-inline-alert-container {
  *:not(i) {
    font-size: inherit !important;
  }
  strong {
    font-weight: bold !important;
  }
  &.ig-inline-alert-size-large {
    @include igTypoStrong();
  }
  padding: 14px;
  &.dissmissable {
    padding-right: 40px;
  }
  position: relative;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  border-radius: $igBorderRadiusM;
  &>i {
    // position: absolute;
    // top: $igTypoPanelParaMediumSize;
    // left: $igTypoPanelParaMediumSize;
    font-size: 16px;
  }
  &>i:not(.custom-icon) {
    font-style: normal;
    font-family: 'gridscale_iconfont';
    font-size: 20px;
    line-height: 1;
  }
  &>span:not(.hide-icon) {
    // padding-left: 30px;
    display: block;
  }
  &>.ig-inline-alert-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    color: var(--icons-color);
    font-size: 20px;
  }


  &.ig-inline-alert-severity-error {
    color: var(--ig-color-alert-error-foreground);
    background: var(--ig-color-alert-error-background);
    &>i:not(.custom-icon):before {
      content: '\E877'; // icon-a119_warning_alt
    }
    &.ig-inline-alert-light-style {
      &>i:not(.custom-icon):before {
        content: '\E83A'; // icon-a059_warning
      }
    }
  }
  &.ig-inline-alert-severity-warning {
    color: var(--ig-color-alert-warning-foreground);
    background: var(--ig-color-alert-warning-background);
    &>i:not(.custom-icon):before {
      content: '\E877'; // icon-a119_warning_alt
    }
    &.ig-inline-alert-light-style {
      &>i:not(.custom-icon):before {
        content: '\E83A'; // icon-a059_warning
      }
    }
  }
  &.ig-inline-alert-severity-success {
    color: var(--ig-color-alert-success-foreground);
    background: var(--ig-color-alert-success-background);
    &>i:not(.custom-icon):before {
      content: '\E88A'; // icon-a138_check_circled
    }
  }
  &.ig-inline-alert-severity-info {
    color: var(--ig-color-alert-info-foreground);
    background: var(--ig-color-alert-info-background);
    &.ig-inline-alert-as-bubble {
      background: #FFF;
      &>i {
        display: none;
      }
      &>span {
        padding-left: initial;
      }
    }
    &>i:not(.custom-icon):before {
      content: '\E84C'; // icon-a077_info
    }
  }

  &.ig-inline-alert-as-bubble {
    border-radius: 2px;
    display: inline-flex;
    position: absolute;
    margin-top: var(--margin-top);

    &:not(.ig-inline-alert-position-none) {
      &:before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        background: inherit;
        transform: rotate(45deg);
      }
    }

    &.ig-inline-alert-position-right {
      margin-left: 16px;
      &:before {
        left: -5px;
        top: 50%;
        margin-top: -5px;

      }
    }
    &.ig-inline-alert-position-left,
    &:not(.ig-inline-alert-position-none):not(.ig-inline-alert-position-right):not(.ig-inline-alert-position-top):not(.ig-inline-alert-position-bottom)
    {
      margin-right: 16px;
      &:before {
        right: -5px;
        top: 50%;
        margin-top: -5px;

      }
    }
    &.ig-inline-alert-position-top {
      margin-bottom: 16px;
      &:before {
        left: 50%;
        margin-left: -5px;
        bottom: -5px;

      }
    }
    &.ig-inline-alert-position-bottom {
      margin-top: 16px;
      &:before {
        left: 50%;
        margin-left: -5px;
        top: -5px;

      }
    }
  }


  &.ig-inline-alert-light-style {
    align-items: center;
    background: none;
    &.ig-inline-alert-severity-info {
      color: var(--ig-color-alert-info-foreground);
      background: var(--ig-color-alert-info-background);
    }
  }

  ::ng-deep {
    ul, ol {
      &>li {
        margin-left: 1.5em;
        line-height: 1.3;
      }
    }
    a {
      color: inherit;
    }
  }
}
